import { Typography, useTheme, Box, Button, Skeleton } from "@mui/material";

import { NavLink } from "react-router-dom";
import { ServiceAPI } from "src/http/server-apis";
import { useQuery } from "react-query";
import { useState } from "react";
import { getImageUrl } from "src/utils";
import { IService } from "src/interface";
import { Link } from "react-router-dom";



function Services() {
  const theme: any = useTheme();
  const [listData, setListData] = useState<Array<IService>>([]);

 const {isLoading}=useQuery(
    ["get-all-servies"],
    () => ServiceAPI("get"),
    {
      refetchOnWindowFocus: false,
      onSuccess(data) {
        if (data?.status === 200)
          setListData(data?.data instanceof Array ? data.data : []);
      },
    }
  );

  return (
    <>
      <div className="flex justify-between items-center mb-5 ">
        <Typography
          variant="h5"
          fontWeight={700}
          color={theme.palette.gray.dark}
        >
          Services
        </Typography>
        <NavLink to="/services">
          <Button size="small" variant="outlined" color="primary">
            View all
          </Button>
        </NavLink>{" "}
        
      </div>

      <div className="grid grid-cols-1 mt-2">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <div className="grid grid-cols-2 gap-4">
          {isLoading
            ? [1, 2,].map((i) => (
                <div
                  key={i}
                  className="flex flex-col justify-center items-center rounded-md p-2 px-1 shadow-md shadow-slate-300 border-b-4 border-slate-300 h-full"
                >
                  <Skeleton
                    variant="rectangular"
                    className="!h-[10vh] w-full rounded-md"
                  />
                  <Skeleton variant="text" width="80%" height={20} />
                </div>
              ))
            : listData?.slice(0,2)?.map((service) => (
                <Link
                  to={`/services/${service.ServiceID}`}
                  key={service.ServiceID}
                >
                  <div className="flex flex-col justify-center items-center rounded-md p-2 px-1 shadow-md shadow-slate-300 border-b-4 border-slate-300 h-full">
                    <div className="h-[10vh] flex items-center justify-center">
                      <img
                        src={ getImageUrl("cars" , service?.Image) }
                        alt="tab content"
                        className="mb-4 h-[10vh] object-cover rounded-md"
                      />
                    </div>
                    <Typography
                      variant="subtitle2"
                      fontWeight={600}
                      className="text-center !leading-5"
                      color={theme.palette.secondary.main}
                    >
                      {service.ServiceName}
                    </Typography>
                  </div>
                </Link>
              ))}
         
          </div>
        </Box>
      </div>
    </>
  );
}

export default Services;
