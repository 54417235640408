import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { RootState } from "src/store/Store";
import { carSchema, profileSchema } from "../common/forms/validation";
import { CustomerAPI, CustomerCarsAPI } from "src/http/server-apis";
import StepTwo from "./stepformsteps/StepTwo";
import StepOne from "./stepformsteps/StepOne";
import StepFour from "./stepformsteps/StepFour";
import { useQuery } from "react-query";
import { FormValues } from "src/interface";
import { UPDATE_ERROR_MESSAGE, UPDATE_MESSAGE } from "src/utils/constants";
import AddCarForm from "../myCars/CarBasicForm";
import useImage from "src/hook/useImage";
import { updaeUserInfo } from "src/store/slice/user/userSlice";
import dayjs from "dayjs";

const EditProfileForm = ({ close }: { close: () => void }) => {
  const theme: any = useTheme();
  const dispatch = useDispatch();
  const { uploadImage } = useImage();

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const { UserInfo, UserID } = useSelector((state: RootState) => state?.user);
  const [actualData] = useState<Record<string, any>>(UserInfo);
  const [userData, setUserData] = useState<Record<string, any>>({});
  const [carData, setCarData] = useState<Record<string, any>>({});

  const steps = ["Basic Info", "Description", "Car", "Upload Images"];
  const [image, setImage] = useState(null);
  const [carImage, setCarImage] = useState(null);

  useEffect(() => {
    if (+actualData.Profile < 100) {
      if (!actualData?.EmailID || !actualData?.FirstName) {
        setActiveStep(0);
        return;
      }
      if (!actualData?.Gender || !actualData?.DOA || !actualData?.DOB) {
        setActiveStep(1);
        return;
      }
      if (!carData?.CarID) {
        setActiveStep(2);
        return;
      }
    }
  }, [actualData, carData]);

  useQuery(
    ["get-customer-cars", UserID],
    () => CustomerCarsAPI("get", { postfix: `?CustomerID=${UserID || ""}` }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data?.status === 200)
          if (data?.data?.length) {
            let car: Record<string, any> = data?.data[0];

            setCarData({
              ...carData,

              CarID: car?.CarID || "",
              Make: car?.Make || "",
              Model: car?.Model || "",
              Year: car?.Year || "",
              IdentificationNumber: car?.IdentificationNumber || "",
              PlateNumber: car?.PlateNumber || "",
              BodyType: car?.BodyType || "",
              Color: car?.Color || "",
              Mileage: car?.Mileage || "",
              TransmissionType: car?.TransmissionType || "",
              FuelType: car?.FuelType || "",
              Image: car?.Image || "",
            });
          } else {
            setCarData({
              ...carData,
              CarID: "",
              Make: "",
              Model: "",
              Year: "",
              IdentificationNumber: "",
              PlateNumber: "",
              BodyType: "",
              Color: "",
              Mileage: "",
              TransmissionType: "",
              FuelType: "",
              Image: "",
            });
          }
      },
    }
  );

  useQuery(
    ["get-customer-by-id", UserInfo.CustomerID],
    () =>
      CustomerAPI("get", {
        params: `${UserInfo.CustomerID}`,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data?.status === 200)
          setUserData({
            ...userData,
            Gender: data?.data?.Gender || "",
            MobileNo: data?.data?.MobileNo || "",
            EmailID: data?.data?.EmailID || "",
            Profile: data?.data?.Profile || "",
            VehicleNumber: data?.data?.VehicleNumber || "",
            FirstName: data?.data?.FirstName || "",
            MiddleName: data?.data?.MiddleName || "",
            LastName: data?.data?.LastName || "",
            Selfie: data?.data?.Selfie || "",
            DOB: data?.data?.DOB || `${dayjs("1994-01-01").format()}`,
            DOA: data?.data?.DOA || `${dayjs("2020-01-01").format()}`,
          });
      },
    }
  );

  const onAdd = async (values: FormValues, image?: string) => {
    try {
      const data = values.CarID
        ? await CustomerCarsAPI("put", {
            params: `${values.CarID}`,
            data: {
              ...values,
              Image: image || values.Image,
            },
          })
        : await CustomerCarsAPI("post", {
            data: {
              ...values,
              Image: image || values.Image,
            },
          });
      if (data?.status === 200)
        if (data.data?.Status === "success") {
          setActiveStep(activeStep + 1);
        } else
          enqueueSnackbar(data.data?.Message || UPDATE_ERROR_MESSAGE, {
            variant: "error",
          });
    } catch (error: any) {
      setLoading(false);

      enqueueSnackbar(error?.response?.data?.Message || UPDATE_ERROR_MESSAGE, {
        variant: "error",
      });
    }
  };

  const onUpdate = async (values: FormValues, image?: string) => {
    try {
      const data = await CustomerAPI("put", {
        params: UserInfo.CustomerID,
        data: {
          ...values,
          CustomerID: UserID,
          Selfie: image || values?.Selfie,
        },
      });
      if (data?.status === 200)
        if (data.data?.Status === "success") {
          enqueueSnackbar(data.data?.Message || UPDATE_MESSAGE, {
            variant: "success",
          });
          if (activeStep !== 3) {
            setActiveStep(activeStep + 1);
            dispatch(updaeUserInfo({ ...UserInfo, ...values }));
          } else close();
        } else
          enqueueSnackbar(data.data?.Message || UPDATE_ERROR_MESSAGE, {
            variant: "error",
          });
    } catch (error: any) {
      setLoading(false);
      enqueueSnackbar(error?.response?.data?.Message || UPDATE_ERROR_MESSAGE, {
        variant: "error",
      });
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  } = useFormik({
    initialValues: {
      ...userData,
      ...carData,
      CustomerID: UserID,
    },
    enableReinitialize: true,
    validationSchema: activeStep === 2 ? carSchema : profileSchema,
    onSubmit: async (values) => {
      setLoading(true);
      if (activeStep === 2) {
        if (carImage) {
          const { data } = await uploadImage(carImage, "cars");
          await onAdd(values, data?.FileName);
        } else onAdd(values);
      } else {
        if (image) {
          const { data } = await uploadImage(image, "profile");
          await onUpdate(values, data?.FileName);
        } else await onUpdate(values);
      }

      setLoading(false);
    },
  });

  const handleBack = () => {
    setActiveStep(+activeStep - 1);
  };

  const handleSkip = async () => {
    setLoading(true);
    await onUpdate(values);
    setLoading(false);
  };

  const getStep = React.useMemo(() => {
    const stepProps = {
      values,
      handleChange,
      errors,
      handleBlur,
      touched,
      setFieldValue,
      activeStep,
      isSubmitting,
    };
    if (activeStep === 1) return <StepTwo {...stepProps} />;
    if (activeStep === 2)
      return (
        <AddCarForm {...stepProps} image={carImage} setImage={setCarImage} />
      );
    if (activeStep === 3)
      return <StepFour {...stepProps} image={image} setImage={setImage} />;
    return <StepOne {...stepProps} />;
  }, [
    activeStep,
    values,
    handleChange,
    errors,
    handleBlur,
    touched,
    setFieldValue,
    isSubmitting,
    image,
    setImage,
    carImage,
    setCarImage,
  ]);

  return (
    <div className="px-[20px] py-5 max-h-[70vh] overflow-auto">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4">
        {getStep}

        <div className="flex justify-between mt-5">
          <Button
            variant="contained"
            color="secondary"
            sx={{
              backgroundColor: theme.palette.gray.main,
              color: theme.palette.gray.dark,
            }}
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Back
          </Button>
          <div className="flex space-x-4">
            {activeStep !== 3 && (
              <>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleSkip}
                  disabled={isSubmitting || loading}
                >
                  Skip
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting || loading}
                >
                  {activeStep === steps.length - 1 ? "Submit" : "Next"}
                </Button>
              </>
            )}
            {activeStep === steps.length - 1 && (
              <Button
                type="submit"
                startIcon={
                  loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : undefined
                }
                variant="contained"
                color="primary"
              >
                Update
              </Button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditProfileForm;
