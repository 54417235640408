export const DELETE_MESSAGE = 'Record deleted';
export const DELETE_ERROR_MESSAGE = 'Record deleted';
export const UPDATE_MESSAGE = 'Record updated';
export const UPDATE_ERROR_MESSAGE = 'Record could not updated';
export const ADD_MESSAGE = 'Record added';
export const ADD_ERROR_MESSAGE = 'Record could not added';
export const FORM_VALIDATION_MESSAGE = 'Required';
export const PLEASE_CHECK_S_NO = 'Please check S No.';
export const FILE_SIZE_EXCEED = 'File size should be less than 15 MB ';
export const IMAGE_SIZE_EXCEED = 'Image size should be less than 10 mb';
export const COULD_NOT_SEARCHED ="Could not searched"
export const CSV_UPLOADED = "CSV upload successfully"
export const CSV_COULD_NOT_UPLOADED = "CSV could not uploaded"
export const PROCESS_IS_NOT_ASSIGNED = "Process is not assigned"
export const WASTE_DATA_NOT_AVAILABLE = "Waste data is not available"
export const DATA_NOT_AVAILABLE = "Data is not available"
export const FILE_IS_REQUIRED ="File is required"
export const IMAGE_REQUIRED ="Image is required"
export const PLEASE_SELECT_AT_LEAST_ONE_OF_THE_QUESTION ="Please select at least one of the question 1 to 12."
export const SOMETHING_WENT_WRONG ="Something went wrong" 
export const AUTHENTICATION_FAILED ="Authentication failed" 
export const MESSAGE_SENT ="Message Sent"
export const MESSAGE_COULD_NOT_SENT ="Message could not sent"
export const TICKET_ACCEPTED ="Ticket accepted"
export const TICKET_COULD_NOT_ACCEPTED ="Ticket could not accepted"


