// eslint-disable-next-line import/no-anonymous-default-export
import { lazy } from "react";
import Loadable from "src/layouts/full/shared/loadable/Loadable";

const Notification = Loadable(
  lazy(() => import("src/views/apps/notification"))
);
const Profile = Loadable(lazy(() => import("src/views/apps/profile")));
const Showrooms = Loadable(lazy(() => import("src/views/apps/showrooms")));
const Workshop = Loadable(lazy(() => import("src/views/apps/workshop")));
const About = Loadable(lazy(() => import("src/views/apps/about")));
const MyCars = Loadable(lazy(() => import("src/views/apps/myCars")));
const Points = Loadable(lazy(() => import("src/views/apps/points")));
const ShareApp = Loadable(lazy(() => import("src/views/apps/shareApp")));
const Services = Loadable(lazy(() => import("src/views/apps/services")));
const MyBookings = Loadable(lazy(() => import("src/views/apps/myBookings")));
const TermsCondition = Loadable(
  lazy(() => import("src/views/apps/termsCondition"))
);
const ProgramBrochure = Loadable(
  lazy(() => import("src/views/apps/programBrochure"))
);
const ServiceDetails = Loadable(
  lazy(() => import("src/views/apps/services/ServiceDetail"))
);
const Faq = Loadable(lazy(() => import("src/views/apps/faq")));
const Support = Loadable(lazy(() => import("src/views/apps/support")));
const GolderMembership = Loadable(
  lazy(() => import("src/views/apps/membership"))
);
const ThankYou = Loadable(lazy(() => import("src/views/apps/thankYou")));


const MembershipDetail = Loadable(
  lazy(() => import("src/views/apps/membership/MembershipDetail"))
);
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  path: "",
  children: [
    {
      path: "notification",
      element: <Notification />,
    },
    {
      path: "profile",
      element: <Profile />,
    },

    {
      path: "about",
      element: <About />,
    },

    {
      path: "showrooms",
      element: <Showrooms />,
    },

    {
      path: "workshop",
      element: <Workshop />,
    },

    {
      path: "myCars",
      element: <MyCars />,
    },

    {
      path: "shareApp",
      element: <ShareApp />,
    },

    {
      path: "thankYou",
      element: <ThankYou />,
    },

    {
      path: "membership",
      children: [
        {
          path: "",
          element: <GolderMembership />,
        },
        {
          path: ":id",
          element: <MembershipDetail />,
        },
      ],
    },

    {
      path: "points",
      element: <Points />,
    },
    {
      path: "services",
      children: [
        {
          path: "",
          element: <Services />,
        },
        {
          path: ":ServiceID",
          element: <ServiceDetails />,
        },
      ],
    },
    {
      path: "mybookings",
      children: [
        {
          path: "",
          element: <MyBookings />,
        },
      ],
    },
    {
      path: "program",
      element: <ProgramBrochure />,
    },
    {
      path: "terms",
      element: <TermsCondition />,
    },
    {
      path: "faq",
      element: <Faq />,
    },
    {
      path: "support",
      element: <Support />,
    },
  ],
};
