import dayjs from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";

/* eslint-disable array-callback-return */
import { enqueueSnackbar } from "notistack";
import { TRights } from "src/interface";
import Elevate from "src/assets/images/elevate.png";
import Amaze from "src/assets/images/amaze_img.png";
import City from "src/assets/images/city.png";
import { getImgUrl } from "src/http/server-base";
import noimg from "src/assets/images/noImg.svg";


dayjs.extend(RelativeTime);

export const RealativeDate = (date: string) => {
  return dayjs(date).fromNow();
};

export function generateYearArray(startYear: number) {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: currentYear - startYear + 1 }, (_, i) => ({
    title: (startYear + i).toString(),
  }));
}


export const getImageUrl = (type: string = "common", fileName: string) => {
  const url = getImgUrl + type + "/" + fileName;
  return fileName ? url : noimg;
};

export const currencyIndiaFormat = (number: number | string) => {
  let Rupee = new Intl.NumberFormat("en-IN").format(+number);
  return Rupee;
};

export const queryToStr = (queryObj: Record<string, string>) => {
  const query = [];
  for (const key in queryObj) {
    query.push(
      encodeURIComponent(key)
        .concat("=")
        .concat(encodeURIComponent(queryObj[key]))
    );
  }
  return query.length ? query.join("&") : "";
};

export const getNameChar = (name: string) => {
  let n = name?.split(" ");
  return n
    ? n[0][0] + (n.length > 1 ? n[n.length - 1][0] : n[0][n[0].length - 1])
    : "A";
};

export const getPayload = (
  original: { [key: string]: any },
  payload?: Array<string>
) => {
  let result: { [key: string]: any } = {};
  if (payload) {
    payload?.map((item) => {
      result[item] = original[item];
    });
  }
  return result;
};
export const checkBoxToString = (checkBoxList: any) => {
  let b = "";
  for (let i = 0; i < checkBoxList?.length; i++) {
    b += `${checkBoxList[i] + (i < checkBoxList?.length - 1 ? "," : "")}`;
  }
  return b;
};
export function sortArrByProperty(array: any, propertyName: string) {
  const newArray = [...array];
  if (newArray?.length > 0 && newArray[0]?.hasOwnProperty(propertyName)) {
    newArray?.sort((a, b) => {
      const valueA = a[propertyName];
      const valueB = b[propertyName];

      return typeof valueA === "string" && typeof valueB === "string"
        ? valueA.localeCompare(valueB)
        : valueA - valueB;
    });
    return newArray;
  } else {
    console.error(`Property ${propertyName} not found in the array objects.`);
  }
}

export function blob2file(blobData: Blob, fileName: string | undefined) {
  const fd = new FormData();
  fd.set("a", blobData, fileName);
  return fd.get("a");
}

export const sortArray = (
  array: Array<Record<string, string>>,
  propertyName: string
) => {
  return array.sort((a, b) =>
    a[propertyName]?.toLowerCase() > b[propertyName]?.toLowerCase()
      ? 1
      : b[propertyName]?.toLowerCase() > a[propertyName]?.toLowerCase()
      ? -1
      : 0
  );
};

export const getTextFromObj = (
  obj: Record<string, string>,
  key: string,
  seprator = ", "
) => {
  let s = "";
  if (Array.isArray(key))
    key.forEach((k, i) => {
      s += obj[k] ? `${i !== 0 ? seprator : ""}${obj[k] || ""}` : "";
    });
  else s = obj[key];

  return s;
};

export const getImageName = (name: string) => {};

export const replacement = (
  string: string,
  replacements: Record<string, string>
) => {
  return string.replace(/%(\w*)%/g, function (m, key) {
    return replacements.hasOwnProperty(key) ? replacements[key] : "";
  });
};

export function capitalizeFirstWord(sentence: string) {
  if (sentence) {
    const words = sentence.split(" ");

    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);

    return words.join(" ");
  } else {
    return sentence;
  }
}
export function capitalizeFirstChar(text: string) {
  if (!text) return text;
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function generateEmailRegex() {
  const username = "[a-zA-Z0-9._%+-]+";
  const domain = "[a-zA-Z0-9.-]+";
  const tld = "[a-zA-Z]{2,}";

  return new RegExp(`^${username}@${domain}\\.${tld}$`);
}

export function roundToDecimalPlaces(value: number, decimalPlaces?: number) {
  const factor = Math.pow(10, decimalPlaces || 2);
  return Math.round(value * factor) / factor || 0;
}
export function fcs() {
  enqueueSnackbar("Feature Coming Soon", {
    variant: "success",
  });
}
export function noRightsToast() {
  enqueueSnackbar("Sorry, you don't have rights contact to admin.", {
    variant: "error",
  });
}

export const createFormField = (
  type: string,
  name: string,
  label: string,
  isRequired?: boolean | undefined,
  className?: string | undefined,
  isLoading?: boolean | undefined,
  data?: any,
  objFilter?: { title: string; value: string } | undefined
) => ({
  type,
  name,
  label,
  isRequired: isRequired || false,
  className: className || "",
  isLoading: isLoading || false,
  options: data || [],
  objFilter: objFilter || {},
});

export const YearData = [
  {
    id: 1,
    year:
      dayjs().get("month") < 3
        ? (dayjs().get("year") - 1).toString()
        : dayjs().get("year")?.toString(),
    title:
      dayjs().get("year")?.toString() +
      "-" +
      dayjs().add(1, "year").get("year")?.toString().substring(2, 4),
  },
  {
    id: 2,
    year: dayjs().subtract(1, "year").get("year")?.toString(),
    title:
      dayjs().subtract(1, "year").get("year")?.toString() +
      "-" +
      dayjs().get("year")?.toString().substring(2, 4),
  },
  {
    id: 3,
    year: dayjs().subtract(2, "year").get("year")?.toString(),
    title:
      dayjs().subtract(2, "year").get("year")?.toString() +
      "-" +
      dayjs().subtract(1, "year").get("year")?.toString().substring(2, 4),
  },
  {
    id: 4,
    year: dayjs().subtract(3, "year").get("year")?.toString(),
    title:
      dayjs().subtract(3, "year").get("year")?.toString() +
      "-" +
      dayjs().subtract(2, "year").get("year")?.toString().substring(2, 4),
  },
  {
    id: 5,
    year: dayjs().subtract(4, "year").get("year")?.toString(),
    title:
      dayjs().subtract(4, "year").get("year")?.toString() +
      "-" +
      dayjs().subtract(3, "year").get("year")?.toString().substring(2, 4),
  },
];
export const MonthsData = [
  { id: 4, value: "April", title: "April", shortTitle: "Apr" },
  { id: 5, value: "May", title: "May", shortTitle: "May" },
  { id: 6, value: "June", title: "June", shortTitle: "Jun" },
  { id: 7, value: "July", title: "July", shortTitle: "Jul" },
  { id: 8, value: "August", title: "August", shortTitle: "Aug" },
  { id: 9, value: "September", title: "September", shortTitle: "Sep" },
  { id: 10, value: "October", title: "October", shortTitle: "Oct" },
  { id: 11, value: "November", title: "November", shortTitle: "Nov" },
  { id: 12, value: "December", title: "December", shortTitle: "Dec" },
  { id: 1, value: "January", title: "January", shortTitle: "Jan" },
  { id: 2, value: "February", title: "February", shortTitle: "Feb" },
  { id: 3, value: "March", title: "March", shortTitle: "Mar" },
];

export const isProduction = () => {
  let mode = process.env.REACT_APP_MODE;

  return mode?.toLowerCase() === "prod" || mode?.toLowerCase() === "stage";
};
export const QuarterData = [
  { title: "Q1", value: "1" },
  { title: "Q2", value: "2" },
  { title: "Q3", value: "3" },
  { title: "Q4", value: "4" },
];
export const PeriodData = [
  { title: "Monthly", value: "1" },
  { title: "Quarterly", value: "3" },
  { title: "Yearly", value: "12" },
];
export const QuoteTerms = [
  {
    Id: 1,
    Active: 0,
    TermName: "Taxes",
    Description: "GST extra as mentioned against each other.",
  },
  {
    Id: 2,
    Active: 1,
    TermName: "Delivery",
    Description:
      "The items will be delivered according to the timelines provided.",
  },
  {
    Id: 3,
    Active: 1,
    TermName: "Installation",
    Description: "As per services quoted above.",
  },
  {
    Id: 4,
    Active: 1,
    TermName: "Validity",
    Description:
      "The quote is valid for orders received on or before the close of business hours of the validity data above.",
  },
  {
    Id: 5,
    Active: 0,
    TermName: "Payment",
    Description: "100 % advance along with Purchase Order",
  },
  {
    Id: 6,
    Active: 1,
    TermName: "Warranty",
    Description: "Warranty on all items is 3 Years from the date of supply",
  },
];
export const GstData = [
  {
    value: "5",
    title: "5%",
  },
  {
    value: "12",
    title: "12%",
  },
  {
    value: "18",
    title: "18%",
  },
  {
    value: "28",
    title: "28%",
  },
];
export const getActiveModule = (rights: TRights, module_name: string) => {
  if (!rights || !rights.length) return false;

  const module = rights?.find((m) => m?.ModuleName === module_name);
  if (!module || !module?.Rights?.length) return false;
  return module?.Rights?.some((right: any) => right?.Active === 1);
};
export function deleteKeyValueInObject<T extends object>(
  key: string,
  obj: T
): void {
  if (key in obj) {
    delete obj[key as keyof T];
  }
}

export const numberToWords = (num: number): string => {
  const units = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];

  const tens = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  const scales = ["", "Thousand", "Lakh", "Crore"];

  if (num === 0) return "Zero";

  let words = "";

  const getWords = (n: number): string => {
    let word = "";

    if (n > 19) {
      word += tens[Math.floor(n / 10)] + (n % 10 ? " " + units[n % 10] : "");
    } else {
      word += units[n];
    }

    return word.trim();
  };

  const convertToWords = (n: number, scale: number): string => {
    let word = "";

    if (n > 0) {
      word +=
        getWords(Math.floor(n / 100)) +
        (Math.floor(n / 100) > 0 ? " Hundred " : "");
      word += getWords(n % 100);
      word += " " + scales[scale];
    }

    return word.trim();
  };

  const crorePart = Math.floor(num / 10000000);
  num = num % 10000000;

  const lakhPart = Math.floor(num / 100000);
  num = num % 100000;

  const thousandPart = Math.floor(num / 1000);
  num = num % 1000;

  const hundredPart = Math.floor(num / 100);
  const remainder = num % 100;

  if (crorePart > 0) {
    words += convertToWords(crorePart, 3) + " ";
  }
  if (lakhPart > 0) {
    words += convertToWords(lakhPart, 2) + " ";
  }
  if (thousandPart > 0) {
    words += convertToWords(thousandPart, 1) + " ";
  }
  if (hundredPart > 0) {
    words += getWords(hundredPart) + " Hundred ";
  }
  if (remainder > 0) {
    words += getWords(remainder);
  }

  return "Rupees " + capitalizeFirstChar(words.trim()) + " only";
};

export function calculateTotalInclGstAmt(
  total: number | string,
  gst: number | string
) {
  const gstAmt = (Number(total) * Number(gst)) / 100;

  let amtWithGst = Number(gstAmt) + Number(total);
  return amtWithGst;
}
export const fuelType = [
  { id: 1, label: "Petrol" },
  { id: 2, label: "Diesel" },
  { id: 3, label: "Hybrid" },
  { id: 4, label: "EV" },
];
export const years = [
  { id: 2010, label: "2010" },
  { id: 2011, label: "2011" },
  { id: 2012, label: "2012" },
  { id: 2013, label: "2013" },
  { id: 2014, label: "2014" },
  { id: 2015, label: "2015" },
  { id: 2016, label: "2016" },
  { id: 2017, label: "2017" },
  { id: 2018, label: "2018" },
  { id: 2019, label: "2019" },
  { id: 2020, label: "2020" },
  { id: 2021, label: "2021" },
  { id: 2022, label: "2022" },
  { id: 2023, label: "2023" },
  { id: 2024, label: "2024" },
  { id: 2025, label: "2025" }
];


export const transmissionType = [
  { id: 1, label: "Manual" },
  { id: 3, label: "Semi-Automatic" },
  { id: 2, label: "Automatic" },
];

export const carsData = [
  {
    id: 1,
    CarName: "Honda Elevate",
    CarImage: Elevate,
  },
  {
    id: 2,
    CarName: "Honda Amaze",
    CarImage: Amaze,
  },
  {
    id: 3,
    CarName: "Honda City",
    CarImage: City,
  },
  {
    id: 4,
    CarName: "Honda City Ehev",
    CarImage: Elevate, // Add appropriate image import for City Ehev
  },
  {
    id: 5,
    CarName: "Mobilio",
    CarImage: Elevate, // Add appropriate image import for Mobilio
  },
  {
    id: 6,
    CarName: "Honda BR-V",
    CarImage: Elevate, // Add appropriate image import for BR-V
  },
  {
    id: 7,
    CarName: "Honda WR-V",
    CarImage: Elevate, // Add appropriate image import for WR-V
  },
  {
    id: 8,
    CarName: "Honda CR-V",
    CarImage: Elevate, // Corrected to a unique CR-V image
  },
  {
    id: 9,
    CarName: "Honda Jazz",
    CarImage: Elevate, // Add appropriate image import for Jazz
  },
  {
    id: 10,
    CarName: "Honda Brio",
    CarImage: Elevate, // Add appropriate image import for Brio
  },
  {
    id: 11,
    CarName: "Honda Accord",
    CarImage: Elevate, // Add appropriate image import for Accord
  },
];

// Define the SupportData array with the IService type
export const SupportData= [
  {
    id: 1,
    ServiceName: "Services",
  },
  {
    id: 2,
    ServiceName: "Tool Change",
  },
  {
    id: 3,
    ServiceName: "Car Repair",
  },
  {
    id: 4,
    ServiceName: "Oil Change",
  },
  {
    id: 5,
    ServiceName: "Tire Replacement",
  },
  {
    id: 6,
    ServiceName: "Brake Inspection",
  },
  {
    id: 7,
    ServiceName: "Battery Check",
  },
  {
    id: 8,
    ServiceName: "Engine Diagnostics",
  },
  {
    id: 9,
    ServiceName: "AC Repair",
  },
  {
    id: 10,
    ServiceName: "Transmission Service",
  },
  {
    id: 11,
    ServiceName: "Wheel Alignment",
  },
  {
    id: 12,
    ServiceName: "Suspension Repair",
  },
  {
    id: 13,
    ServiceName: "Fluid Flush",
  },
  {
    id: 14,
    ServiceName: "Exhaust System Repair",
  },
  {
    id: 15,
    ServiceName: "Fuel System Cleaning",
  },
  {
    id: 16,
    ServiceName: "Headlight Restoration",
  },
  {
    id: 17,
    ServiceName: "Paint Protection",
  },
  {
    id: 18,
    ServiceName: "Windshield Repair",
  },
  {
    id: 19,
    ServiceName: "Detailing",
  },
  {
    id: 20,
    ServiceName: "Roadside Assistance",
  },
];
