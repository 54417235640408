import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import imageCompression from 'browser-image-compression';
import { blob2file } from '../utils';
import { appUrl } from '../http/server-base';
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 800,
  useWebWorker: true,
};

const useImage = () => {
  const uploadImage = async (
    file: any,
    type = 'common',
    compressed = false
  ) => {
    const compressedFile = await imageCompression(file, options);
    const file2 = blob2file(compressedFile, compressedFile.name);
    let file3 = compressed ? file2 : file;
    let Formdata = new FormData();
    let progress = 0;
    Formdata.append(
      'FileName',
      file3,
      uuidv4() + '.' + file?.name?.split('.')?.slice(-1)?.pop()
    );
    Formdata.append('Type', type);
    const config = {
      onUploadProgress: (progressEvent: any) => {
        progress = Math.round(
          (100 * progressEvent.loaded) / progressEvent.total
        );
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    try {
      const response = await axios.post(
        appUrl +  'uploads.php',
        Formdata,
        config
      );
      if (response.status === 200)
        return { data: response.data, progress: progress, err: null };
      else return { err: 'Image could not uploaded', data: null };
    } catch (error) {
      return { err: 'Something went wrong', data: null };
    }
  };

  const downloadImage = async (src: string) => {
    try {
      const response = await axios.get(src, {
        responseType: 'blob',
      });
      if (response.status === 200) return { data: response.data, err: null };
      else return { err: 'Image could not downloaded' };
    } catch (err) {
      return { err: 'Something went wrong', data: null };
    }
  };
  return {
    uploadImage,
    downloadImage,
  };
};
export default useImage;