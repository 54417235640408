import { Button, Drawer, Typography, useTheme } from "@mui/material";
import { BiSolidXCircle } from "react-icons/bi";
import { useFormik } from "formik";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { UPDATE_ERROR_MESSAGE } from "src/utils/constants";
import { ServiceAPI, ShareAPI } from "src/http/server-apis";
import { RootState } from "src/store/Store";
import { FormValues } from "src/interface";
import { shareSchema } from "src/components/common/forms/validation";
import CustomTextField from "src/components/common/forms/input-elements/CustomTextField";
import { useNavigate } from "react-router";
import { useQuery } from "react-query";
import AsyncAutocomplete from "src/components/common/forms/input-elements/AsyncAutoComplete";

const AddShareModal = ({
  open,
  close,
  actualValues,
}: {
  open: boolean;
  close: VoidFunction;
  actualValues?: any;
}) => {
  const navigate = useNavigate();
  const { UserID } = useSelector((state: RootState) => state?.user);
  const theme: any = useTheme();

  const [loading, setLoading] = useState(false);

  const { data: serviceData, isLoading: serviceLoading } = useQuery(
    ["get-services-option"],
    () => ServiceAPI("get")
  );

  const onAdd = async (values: FormValues, image?: string) => {
    try {
      const data = await ShareAPI("post", {
        data: {
          ...values,
        },
      });
      if (data?.status === 200)
        if (data.data?.Status === "success") {
          close();
          navigate(
            "/thankYou?msg=Thank you for referring a friend!&description=We appreciate your support! Your friend will enjoy the amazing features we offer, and we've sent them the details. They'll be able to explore everything shortly!"
          );
        } else
          enqueueSnackbar(data.data?.Message || UPDATE_ERROR_MESSAGE, {
            variant: "error",
          });
    } catch (error: any) {
      enqueueSnackbar(error?.response?.data?.Message || UPDATE_ERROR_MESSAGE, {
        variant: "error",
      });
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      CustomerID: actualValues?.CustomerID || UserID,
      PersonName: "",
      MobileNo: "",
      ServiceID: "",
    },

    validationSchema: shareSchema,
    async onSubmit(values) {
      setLoading(true);

      onAdd(values);

      setLoading(false);
    },
  });

  return (
    <Drawer
      open={open}
      onClose={close}
      anchor="bottom"
      sx={{
        "& .MuiPaper-root": {
          borderTopLeftRadius: 25,
          borderTopRightRadius: 25,
          overflow: "auto",
          maxHeight: "80vh",
        },
      }}
    >
      <BiSolidXCircle
        className="absolute  right-1 "
        size={35}
        color={theme.palette.primary.main}
        onClick={close}
      />
      <div className="px-[20px] py-5">
        <Typography
          variant="h5"
          color={theme.palette.text.primary}
          className="text-center !mb-8"
        >
          Refer a friend
        </Typography>
        <form className="grid grid-cols-1 gap-2" onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-3 my-5">
            <CustomTextField
              type="text"
              fullWidth
              name="PersonName"
              label="Friend's Name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.PersonName}
              error={!!(errors.PersonName && touched.PersonName)}
              helperText={touched?.PersonName ? errors?.PersonName : ""}
              isRequired
            />
            <AsyncAutocomplete
              id="ServiceID"
              label="Interested in "
              loading={serviceLoading}
              isRequired
              options={serviceData?.data || []}
              objFilter={{
                value: "ServiceID",
                title: "ServiceName",
              }}
              value={values?.ServiceID}
              onChangeOption={(value) => setFieldValue("ServiceID", value)}
              TextInputProps={{
                onBlur: handleBlur,
                error: !!(errors.ServiceID && touched.ServiceID),
                helperText: touched?.ServiceID ? errors?.ServiceID : "",
              }}
            />
            <CustomTextField
              type="number"
              fullWidth
              name="MobileNo"
              label="Phone Number"
              isRequired={true}
              value={values.MobileNo}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.MobileNo && Boolean(errors.MobileNo)}
              helperText={touched.MobileNo ? errors.MobileNo : ""}
            />

            
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="w-full mt-5"
            disabled={loading || isSubmitting}
          >
            Share
          </Button>
        </form>
      </div>
    </Drawer>
  );
};

export default AddShareModal;
