import { useState } from "react";
import CustomTextField, {
  Label,
} from "../common/forms/input-elements/CustomTextField";
import AsyncAutocomplete from "../common/forms/input-elements/AsyncAutoComplete";
import { fuelType, transmissionType, years } from "src/utils";
import { IFormProps } from "src/interface";
import { useQuery } from "react-query";
import { CarsAPI } from "src/http/server-apis";
import { Grid, Typography } from "@mui/material";
import FileUploader from "../common/forms/input-elements/FileUploader";
import HondaCar from "src/assets/images/Amaze.png";
import OtherCar from "src/assets/images/car_placeholder.png";

interface IProps extends IFormProps {
  image?: any;
  setImage?: any;
}

const CarBasicForm: React.FC<IProps> = ({
  values,
  errors: err,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
  image,
  setImage,
}) => {
  const [carOption, setCarOption] = useState("1");
  const [carData, setCarData] = useState<Record<string, any>[]>([]);
  const errors: any = err;

  const { isLoading } = useQuery(["get-cars"], () => CarsAPI("get", {}), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (data?.status === 200) {
        setCarData(data.data || []);
      }
    },
  });

  return (
    <div className="grid grid-cols-1 gap-3 my-5">
      <div className="flex items-center gap-4">
        <div
          className={`cursor-pointer px-4 py-2 border rounded text-center ${
            carOption === "1" ? "bg-[#f1ccd6] border-[#e499ac] text-[#e60121]" : "bg-gray-100"
          }`}
          onClick={() => {
            setFieldValue("IsHonda", "1");
            setCarOption("1");
          }}
        >
          <img src={HondaCar} alt="honda" />
          <Typography variant="subtitle1">Honda</Typography>
        </div>
        <div
          className={`cursor-pointer px-4 py-2 border rounded text-center ${
            carOption === "0" ? "bg-[#f1ccd6] border-[#e499ac] text-[#e60121]" : "bg-gray-100"
          }`}
          onClick={() => {
            setFieldValue("IsHonda", "0");
            setCarOption("0");
          }}
        >
          <img src={OtherCar} alt="honda" />
          <Typography variant="subtitle1">Other Car</Typography>

          
        </div>
      </div>
      <div className="grid grid-cols-3 gap-2">
        <div className="col-span-2">
          {carOption === "1" ? (
            <AsyncAutocomplete
              id="Make"
              label="Car"
              isRequired
              options={carData}
              loading={isLoading}
              objFilter={{
                value: "CarName",
                title: "CarName",
                image: "CarImage",
                type: "cars",
              }}
              value={values?.Make}
              onChangeOption={(value) => {
                setFieldValue("Make", value);
                // setFieldValue(
                //   "Image",
                //   carData?.find((c) => c.CarName === value)?.CarImage || ""
                // );
              }}
              TextInputProps={{
                onBlur: handleBlur,
                error: !!(errors.Make && touched.Make),
                helperText: touched?.Make ? errors?.Make : "",
              }}
            />
          ) : (
            <>
              <CustomTextField
                type="text"
                fullWidth
                name="Make"
                label="Car/Company/Brand"
                isRequired
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.Make}
                error={!!(errors.Make && touched.Make)}
                helperText={touched?.Make ? errors?.Make : ""}
              />
            </>
          )}
        </div>

        <AsyncAutocomplete
          id="Year"
          label="Year "
          options={years}
          isRequired={true}
          objFilter={{ value: "label", title: "label" }}
          onChangeOption={(value) => setFieldValue("Year", value)}
          value={values?.Year}
        />
      </div>
      <CustomTextField
        type="text"
        fullWidth
        name="PlateNumber"
        label="Vehicle Number"
        onChange={handleChange}
        textLimit={10}
        onBlur={handleBlur}
        value={values.PlateNumber}
        error={!!(errors.PlateNumber && touched.PlateNumber)}
        helperText={touched?.PlateNumber ? errors?.PlateNumber : ""}
        isRequired
      />
      <div className="grid grid-cols-2 gap-2">
        <AsyncAutocomplete
          id="FuelType"
          label="Fuel Type"
          options={fuelType}
          objFilter={{ value: "label", title: "label" }}
          onChangeOption={(value) => setFieldValue("FuelType", value)}
          value={values?.FuelType}
        />
        <CustomTextField
          type="text"
          fullWidth
          name="Color"
          label="Color"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.Color}
          error={!!(errors.Color && touched.Color)}
          helperText={touched?.Color ? errors?.Color : ""}
        />
      </div>

      <div>
        <label className="block font-medium text-gray-700 mb-2 text-[12px]">
          Transmission Type
        </label>
        <div className="flex items-center gap-4">
          {transmissionType.map((option) => (
            <label key={option.label} className="flex items-center gap-2">
              <input
                type="radio"
                name="TransmissionType"
                value={option.label}
                checked={values?.TransmissionType === option.label}
                onChange={() => setFieldValue("TransmissionType", option.label)}
              />
              {option.label}
            </label>
          ))}
        </div>
        {touched?.TransmissionType && errors?.TransmissionType && (
          <p className="text-red-500 text-sm mt-1">
            {errors?.TransmissionType || ""}
          </p>
        )}
      </div>

      {(image || setImage) && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Label>Image</Label>
          </Grid>
          <Grid item xs={12}>
            <FileUploader
              handleChange={(file: any) => {
                setImage(file);
              }}
              src={image || values["Image"]}
              type={"cars"}
              download={image ? false : true}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default CarBasicForm;
