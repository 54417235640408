/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { styled, Typography } from "@mui/material";
import { FileUploader as ReactFileUploader } from "react-drag-drop-files";
import NoImg from "src/assets/images/noImg.svg";
import { getImageUrl } from "src/utils";
const ImageContainer = styled("div")`
  --tw-border-opacity: 1;
  align-items: center;
  border-color: #d1dbd9;
  border-radius: 0.5rem;
  border-style: dashed;
  border-width: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;

  :hover {
    border-color: #000;
  }

  img {
    margin-left: auto;
    margin-right: auto;
  }
`;

const LabelContainer = styled("div")`
  text-align: center;
`;

export default function FileUploader(props) {
  const { handleChange, multiple, src, type, download } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [img, setImg] = useState();

  const sizeError = (file) =>
    enqueueSnackbar(file, {
      variant: "error",
    });

  const typeError = (err) => enqueueSnackbar(err, { variant: "error" });
  useEffect(() => {
    if (src instanceof File && !download) {
      const reader = new FileReader();
      reader.readAsDataURL(src);
      reader.onload = () => {
        if (typeof reader.result === "string") {
          setImg(reader.result);
        }
      };
    }
  }, [src, download]);

  console.log(src);

  return (
    <ReactFileUploader
      types={["JPEG", "PNG", "JPG", "WEBP"]}
      onTypeError={typeError}
      onSizeError={sizeError}
      multiple={multiple || false}
      maxSize={2}
      handleChange={handleChange}
    >
      <ImageContainer sx={{ p: 1, minHeight: "fit-content", height: 200 }}>
        <LabelContainer sx={{ mb: 4, mt: 2 }}>
          <Typography>
            <img
              src={src ? (download ? getImageUrl(type, src) : img) : NoImg}
              alt="image-logo"
              style={{
                width: 90,
              }}
              className="object-contain"
            />
            <Typography component={"span"} color="black">
              Drop your image here, or{" "}
            </Typography>
            <Typography component={"span"} color="blue">
              browse
            </Typography>
          </Typography>
          <Typography
            sx={{
              color: "neutral.400",
            }}
          >
            Image size should be square (500) x (500),
            <br />
            Image size must be less than 2 mb
            <br />
            Support: jpeg, png, jpg
          </Typography>
        </LabelContainer>
      </ImageContainer>
    </ReactFileUploader>
  );
}
