import { Box, Button, Card, Drawer, Typography, useTheme } from "@mui/material";
import {
  BiChevronRight,
  BiDownload,
  BiFile,
  BiHeart,
  BiShareAlt,
  BiSolidXCircle,
} from "react-icons/bi";

import PageContainer from "src/components/common/components/PageContainer";
import firstStep from "../../assets/images/first_step.png";
import secStep from "../../assets/images/sec_step.png";
import thirdStep from "../../assets/images/thi_step.png";
import fourthStep from "../../assets/images/fourth_step.jpg";

import PointTabs from "src/components/dashboard/PointInfo";
import MemberOffers from "src/components/dashboard/Services";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/Store";
import Carousel from "src/components/slider";
import { BannersAPI, CustomerAPI } from "src/http/server-apis";
import { useQuery } from "react-query";
import LoginImg from "../../assets/images/login_banner.png";
import { Link, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import EditProfileForm from "src/components/editProfileForm/EditProfileForm";
import { useEffect, useState } from "react";
import { setUserInfo } from "src/store/slice/user/userSlice";
import AddShareModal from "src/components/dashboard/share/AddShareModal";

const Dashboard = () => {
  const theme: any = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const share = queryParams.get("share");
  const [open, setOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);

  const { UserInfo, UserID } = useSelector((state: RootState) => state?.user);
  const { data } = useQuery(
    ["get-banners"],
    () => BannersAPI("get", { postfix: `?Active=1` }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const getUser = async () => {
    try {
      const res = await CustomerAPI("get", {
        params: `${UserID}`,
      });

      if (res?.status === 200) {
        dispatch(setUserInfo(res.data));
      }
    } catch (error) {}
  };
  const toggleDrawer = (v: boolean) => {
    setOpen(v);
    if (!v) getUser();
  };

  useEffect(() => {
    if (share)

      setShareOpen(true);
  }, [share,]);

  return (
    <PageContainer title="Dashboard">
      <div className="grid grid-cols-1 gap-3 py-3">
        {UserID ? (
          <Card className="!bg-[url('./assets/images/white_wave.png')] bg-cover bg-no-repeat">
            <Box className="grid grid-cols-2 gap-3 ">
              <Box component="div" className="py-5 gap-1 flex ">
                <Typography variant="h6" color={theme.palette.gray.main}>
                  Hi
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight={700}
                  color={theme.palette.primary.main}
                >
                  {UserInfo?.FirstName || "New User"}{" "}
                  {UserInfo?.MiddleName || ""} {UserInfo?.LastName || ""}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={theme.palette.warning.main}
                >
                  {UserInfo.CustomerName ? "Sliver Member (Tier 1)" : null}
                </Typography>
              </Box>

              <Box
                component="div"
                className="pt-5 text-end flex flex-col justify-center"
              >
                <Typography
                  variant="h6"
                  fontWeight={700}
                  color={theme.palette.gray.dark}
                >
                  {/* {UserInfo?.VehicleNumber || "-"} */}
                </Typography>
                <Typography variant="body2" color={theme.palette.gray.main}>
                  {/* Vehicle Number */}
                </Typography>
              </Box>
            </Box>
            {/* <Box
                className="grid grid-cols-1 rounded-md relative p-5"
                sx={{
                  backgroundColor: theme.palette.primary.main,
                }}
              >
                <div className="flex justify-between items-center relative">
                  <Typography variant="h6" fontWeight={900} color="white">
                    {UserInfo?.ProfileScore ? UserInfo?.ProfileScore : 25}%
                    Completed
                  </Typography>
                  {+UserInfo?.ProfileScore !== 100 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      className="relative z-30 !border-white  !bg-white !text-red-800 !font-semibold "
                      size="small"
                      onClick={() => toggleDrawer(true)}
                    >
                      Update Profile
                    </Button>
                  ) : null}
                </div>

                <img
                  src={carImage}
                  alt="car"
                  className="w-[30%] opacity-20 absolute right-0 top-0"
                />
                <Box className="mt-8">
                  <CustomeSilder
                    value={
                      UserInfo?.ProfileScore ? +UserInfo?.ProfileScore : 25
                    }
                    onChange={() => {}}
                  />
                </Box>
                <Typography
                  variant="subtitle2"
                  className="text-center mt-5"
                  color="white"
                >
                  Earn <span className="text-sm font-semibold">1000</span> more
                  points to upgrade tiers
                </Typography>
              </Box> */}
            <img
              src={
                +UserInfo?.Profile <= 25
                  ? firstStep
                  : +UserInfo?.Profile <= 50
                  ? secStep
                  : +UserInfo?.Profile <= 75
                  ? thirdStep
                  : fourthStep
              }
              alt="login"
              onClick={() =>
                +UserInfo?.Profile <= 75 ? toggleDrawer(true) : null
              }
              className="bg-white"
            />

            <div className="grid grid-cols-3 gap-4 py-5">
              <Box
                component="div"
                className=" gap-1 flex flex-col justify-center "
              >
                <Typography
                  variant="h3"
                  fontWeight={700}
                  color={theme.palette.secondary.main}
                >
                  {+UserInfo?.Points || 0}
                </Typography>
                <Typography variant="body2" color={theme.palette.slate.dark}>
                  Available Points
                </Typography>
              </Box>

              <Box
                component="div"
                className=" gap-1 flex flex-col justify-center"
              >
                <Typography
                  variant="h3"
                  fontWeight={700}
                  color={theme.palette.secondary.main}
                >
                  10
                </Typography>
                <Typography variant="body2" color={theme.palette.slate.dark}>
                  Expiring in 30 Days
                </Typography>
              </Box>
              <NavLink to={"/membership/2"}>
                <Button
                  color="primary"
                  className="flex !px-0"
                  variant="contained"
                  size="large"
                  type="submit"
                >
                  <span className="flex flex-1 px-3 ">Benefits</span>{" "}
                  <BiChevronRight size={30} />
                </Button>
              </NavLink>
            </div>
          </Card>
        ) : (
          <Card>
            <Link to="/auth/login">
              <img src={LoginImg} alt="login" className="rounded-md" />
            </Link>
          </Card>
        )}

        <Card>
          <Carousel imgKey="Banner" data={data?.data} fade={false} />
        </Card>
        <Card className="!bg-gradient-to-r  from-[#e60121] to-[#8a0114]">
          <Link
            to="/program"
            className="flex items-center justify-between text-white "
          >
            <div className="flex items-center gap-2">
              <BiFile size={20} />
              <Typography variant="subtitle1">Download Brochures</Typography>
            </div>
            <BiDownload size={20} />
          </Link>
        </Card>
        <Card>
          <MemberOffers />
        </Card>

        <Card>
          <PointTabs />
        </Card>

        {UserID ? (
          <Card className="!bg-gradient-to-r  from-[#e60121] to-[#8a0114]">
            <div
              onClick={() => setShareOpen(true)}
              className="flex items-center justify-between text-white "
            >
              <div className="flex items-center gap-2">
                <BiHeart size={20} />
                <Typography variant="subtitle1">Refer a Friend</Typography>
              </div>
              <BiShareAlt size={20} />
            </div>
          </Card>
     ) : null}  
      </div>

      <Drawer
        open={open}
        onClose={() => toggleDrawer(false)}
        anchor="bottom"
        sx={{
          "& .MuiPaper-root": {
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
            overflow: "visible",
          },
        }}
      >
        <BiSolidXCircle
          className="absolute  right-1 "
          size={35}
          color={theme.palette.primary.main}
          onClick={() => toggleDrawer(false)}
        />
        <EditProfileForm close={() => toggleDrawer(false)} />
      </Drawer>

      {shareOpen ? (
        <AddShareModal
          open={shareOpen}
          close={() => setShareOpen(false)}
          actualValues={{}}
        />
      ) : null}
    </PageContainer>
  );
};

export default Dashboard;
