import React from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  Label,
} from "src/components/common/forms/input-elements/CustomTextField";
import CustomDatePicker from "src/components/common/forms/input-elements/CustomDatePicker";
import { IFormProps } from "src/interface";

const StepTwo: React.FC<IFormProps> = ({
  values,
  handleBlur,
  touched,
  errors,
  setFieldValue,
  handleChange,
}) => {

  return (
    <div className="px-[20px] py-5">

      <form className="grid grid-cols-1 gap-2">
        <CustomDatePicker
          name={"DOB"}
          label="Date of Birth"
          handleChange={(value) => setFieldValue("DOB", value)}
          value={values.DOB}
          TextInputProps={{
            error:Boolean( !!errors["DOB"] && touched["DOB"],),
            helperText: touched["DOB"] ? errors["DOB"] : "",
            onBlur: handleBlur,
          }}
        />
        <CustomDatePicker
          name={"DOA"}
          label="Anniversary Date"
          handleChange={(value) => setFieldValue("DOA", value)}
          value={values.DOA}
          TextInputProps={{
            error: Boolean(!!errors["DOA"] && touched["DOA"]),
            helperText: touched["DOA"] ? errors["DOA"] : "",
            onBlur: handleBlur,
          }}
        />
         <FormControl component="fieldset">
          <Label>Gender</Label>
          <RadioGroup
            name="Gender"
            value={values.Gender}
            onChange={handleChange}
            row
          >
            <FormControlLabel value="Male" control={<Radio size="small" />} label="Male" />
            <FormControlLabel
              value="Female"
              control={<Radio />}
              label="Female"
            />
            <FormControlLabel value="Other" control={<Radio size="small" />} label="Other" />
          </RadioGroup>
        </FormControl>
      
      </form>
    </div>
  );
};

export default StepTwo;
