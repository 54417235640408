import React from "react";
import { Grid } from "@mui/material";

import { IFormProps } from "src/interface";
import FileUploader from "src/components/common/forms/input-elements/FileUploader";
import { Label } from "src/components/common/forms/input-elements/CustomTextField";

interface IFourthFrormProps extends IFormProps {
  image: any;
  setImage: any;
}

const StepFour: React.FC<IFourthFrormProps> = ({
  values,
  image,
  setImage,
}) => {

  
  return (
    <div className="px-[20px] py-5">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Label>User Image</Label>
        </Grid>
        <Grid item xs={12}>
          <FileUploader
            handleChange={(file: any) => {
              setImage(file);
            }}
            src={image || values["Selfie"]}
            type={"profile"}
            download={image ? false : true}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default StepFour;
