import { baseFunc } from "./server-base";

export const UploadsAPI = baseFunc("uploads");
export const UsersAPI = baseFunc("users");
export const ChatNotificationAPI = baseFunc("users");
export const SupportTicketAPI = baseFunc("users");

export const CustomerAPI = baseFunc("customers.php");
export const CustomerCarsAPI = baseFunc("customercars.php");
export const FaqsAPI = baseFunc("faqs.php");
export const BannersAPI = baseFunc("banners.php");
export const EarningmethodsAPI = baseFunc("earningmethods.php");
export const CustomerearningpointsAPI = baseFunc("customerearningpoints.php");
export const ServicebookingAPI = baseFunc("servicebooking.php");
export const ServiceAPI = baseFunc("services.php");
export const AccessoriesAPI = baseFunc("accessories.php");
export const SupportsAPI = baseFunc("supports.php");
export const CarsAPI = baseFunc("cars.php");
export const ShareAPI = baseFunc("share.php");

