import * as yup from "yup";
import {
  minText,
  maxText,
  requiredText,
  // phoneRegExp,
  emailRegExp,
  // vehicleRegExp,
} from "./message";

export const profileSchema = yup.object({
  FirstName: yup
    .string()
    .min(2, minText("Name", 2))
    .max(50, maxText("Name", 50))
    .required(requiredText("First Name")),

  // Gender: yup
  //   .string()
  //   .oneOf(["Male", "Female", "Other"], "Invalid gender")
  //   .required(requiredText("Gender")),

  // MobileNo: yup
  //   .string()
  //   .min(10, minText("Phone Number", 10))
  //   .max(10, maxText("Phone Number", 10))
  //   .matches(phoneRegExp, "Invalid phone number")
  //   .required(requiredText("Phone Number")),

  EmailID: yup
    .string()
    .matches(emailRegExp, "Invalid email address")
    .required(requiredText("Email")),
  // Profile: yup.string().nullable(),
  // VehicleNumber: yup.string().nullable(),
});

export const carSchema = yup.object({
  Make: yup
    .string()
    .min(2, minText("Car Name", 2))
    .max(50, maxText("Car Name", 50))
    .required(requiredText("Car Name")),

  Year: yup
    .string()
    .min(4, minText("Year", 4))
    .max(4, maxText("Year", 4))
    .required(requiredText("Year")),
  PlateNumber: yup.string().required(requiredText("Vehicle Number")),
});

export const shareSchema = yup.object({
  PersonName: yup
    .string()
    .min(2, minText("Friend's Name", 2))
    .max(50, maxText("Friend's Name", 50))
    .required(requiredText("Friend's Name")),
  MobileNo: yup
    .string()
    .min(10, minText("Phone Number", 10))
    .max(10, maxText("Phone Number", 10))
    .required(requiredText("Phone Number")),
  ServiceID: yup
    .string()
    .required(requiredText("Interested in")),
});
export const bookServiceSchema = yup.object({
  ServiceID: yup.string().required(requiredText("Type of Service")),
  CustomerName: yup.string().required(requiredText("Name")),
  MobileNo: yup.string().required(requiredText("Mobile Number")),
  CarID: yup.string().when(["ServiceID", "BuyOrSell"], {
    is: (ServiceID: any, BuyOrSell: boolean) =>
      ((+ServiceID === 1 && +BuyOrSell !== 1) || +ServiceID !== 1) &&
      +ServiceID !== 22,
    then: (schema) => schema.required(requiredText("Car Name")),
    otherwise: (schema) => schema,
  }),

  RegistrationNo: yup.string().when(["ServiceID", "BuyOrSell"], {
    is: (ServiceID: any, BuyOrSell: boolean) =>
      ((+ServiceID === 1 && +BuyOrSell !== 1) || +ServiceID !== 1) &&
      +ServiceID !== 22,
    then: (schema) => schema.required(requiredText("Registration Number")),
    otherwise: (schema) => schema,
  }),

  PurchaseYear: yup.string().when(["ServiceID", "BuyOrSell"], {
    is: (ServiceID: any) => +ServiceID === 4 || +ServiceID === 5,
    then: (schema) => schema.required(requiredText("Purchase Year")),
    otherwise: (schema) => schema,
  }),

  OdoMeterReading: yup.string().when(["ServiceID"], {
    is: (ServiceID: any) => +ServiceID === 6,
    then: (schema) => schema.required(requiredText("OdoMeter Reading")),
    otherwise: (schema) => schema,
  }),
  Model: yup.string().when("ServiceID", {
    is: (ServiceID: any) => +ServiceID === 22,
    then: (schema) => schema.required(requiredText("Model")),
    otherwise: (schema) => schema,
  }),
  Oulet: yup.string().required(requiredText("Outlet")),
});

export const requestPointSchema = yup.object({
  MethodID: yup.string().required(requiredText("Earning By")),
  CarNumber: yup.string().required(requiredText("Car Name")),
  Date: yup.string().required(requiredText("Date")),
  Message: yup.string(),
});

export const supportSchema = yup.object({
  Name: yup.string().required(requiredText("Name")),
  QueryType: yup.string().required(requiredText("Query Type")),
  EmailID: yup.string().required(requiredText("Email")),
  PhoneNo: yup.string().required(requiredText("Phone No.")),
  Message: yup.string(),
});

export const StepOneSchema = yup.object({
  Description: yup
    .string()
    .required("Description is required")
    .max(125, "Description cannot exceed 125 characters"),
  Gender: yup.string().required("Gender is required"),
});

export const StepTwoSchema = yup.object({
  Address: yup.string().required("Address is required"),
  PhoneNumber: yup
    .string()
    .matches(/^\d{10}$/, "Phone number must be 10 digits")
    .required("Phone number is required"),
});
