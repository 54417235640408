import { Typography, useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";

const TabCard = ({ title, image, href, onClick }: any) => {
  const theme: any = useTheme();

  return (
    <NavLink to={onClick ? "#" : href} onClick={onClick}>
      <div className="  flex flex-col justify-center items-center rounded-md p-2 px-1 shadow-md shadow-slate-300 border-b-4 border-slate-300 h-full">
        <div className="h-[7vh] flex items-center justify-center">
          <img
            src={image}
            alt="warranty"
            className="w-[60%]  mb-4 rounded-md"
          />
        </div>
        <Typography
          variant="subtitle2"
          fontWeight={600}
          className="text-center !leading-5"
          color={theme.palette.secondary.main}
        >
          {title}
        </Typography>
      </div>
    </NavLink>
  );
};

export default TabCard;
