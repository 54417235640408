import React from "react";
import CustomTextField from "src/components/common/forms/input-elements/CustomTextField";
import { IFormProps } from "src/interface";

const StepOne: React.FC<IFormProps> = ({
  values,
  handleBlur,
  touched,
  errors,
  handleChange,
}) => {

  return (
    <div className="px-[20px] py-5">
      <form className="grid grid-cols-1 gap-2">
        <CustomTextField
          type={"text"}
          fullWidth
          name="FirstName"
          isRequired
          label="First Name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.FirstName}
          error={!!(errors.FirstName && touched.FirstName)}
          helperText={touched?.FirstName ? errors?.FirstName : ""}
        />
        <CustomTextField
          type={"text"}
          fullWidth
          name="MiddleName"
          label="Middle Name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.MiddleName}
          error={!!(errors.MiddleName && touched.MiddleName)}
          helperText={touched?.MiddleName ? errors?.MiddleName : ""}
        />
        <CustomTextField
          type={"text"}
          fullWidth
          name="LastName"
          label="Last Name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.LastName}
          error={!!(errors.LastName && touched.LastName)}
          helperText={touched?.LastName ? errors?.LastName : ""}
        />
        <CustomTextField
          type={"text"}
          fullWidth
          name="EmailID"
          isRequired
          label="Email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.EmailID}
          error={!!(errors.EmailID && touched.EmailID)}
          helperText={touched?.EmailID ? errors?.EmailID : ""}
        />
      </form>
    </div>
  );
};

export default StepOne;
