import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getImgUrl } from "src/http/server-base";
// import banner from "../../assets/images/banner.jpg";

function Carousel(props: any) {
  const { data, divCss, imgCss, imgKey, from = "banners/" } = props;
  const settings = {
    dots: false,
    navigator: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1500,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    ...props,
  };
  if (!data?.length) {
    return <div className="grid grid-cols-1"></div>;
  }
  if (data?.length === 1) {
    return (
      <div className={`slider-container  rounded-md  ${divCss}`}>
        <img
          src={getImgUrl + from + data[0]?.[imgKey]}
          alt="single-slide"
          className={`w-full  object-cover rounded-md   bg-white ${imgCss}`}
        />
      </div>
    );
  }
  return (
    <div className={`slider-container  rounded-md   ${divCss}`}>
      <Slider
        sx={{
          "& .slick-dots": {
            bottom: "80%",
          },
        }}
        {...settings}
      >
        {data?.map((slide: any, index: number) => {
          return (
            <div className="h-[20vh] bg-white rounded-md" key={slide?.[imgKey]}>
              <img
                src={getImgUrl + from + slide?.[imgKey]}
                alt={slide?.[imgKey]}
                className={`w-full  object-contain rounded-md  bg-white h-[20vh] ${imgCss}`}
                loading="lazy"
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default Carousel;
