import { Box, Card, Chip, Typography, useTheme } from "@mui/material";
import React, { memo } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { CustomerearningpointsAPI } from "src/http/server-apis";
import { RootState } from "src/store/Store";
import CoinsImg from "src/assets/images/two_coins.png";
import dayjs from "dayjs";
import { BiError } from "react-icons/bi";
const RequestTab = () => {
  const theme = useTheme();
  const { UserID } = useSelector((state: RootState) => state?.user);
  const { data } = useQuery(
    ["get-customerearning-point", UserID],
    () =>
      CustomerearningpointsAPI("get", {
        postfix: `?CustomerID=${UserID || ""}`,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
  return (
    <>
      {data?.data.length ? data.data?.map(
        (x: { MethodName: string; Message: string; Date: string }) => (
          <RequestCard
            title={x.MethodName}
            Description={x.Message}
            Date={x.Date}
            Points=""
            theme={theme}
          />
        )
      ) :  
      
     <Box className="col-span-3 bg-white border rounded-lg py-8 px-3 flex flex-col justify-center items-center">
        <BiError size={40} className="opacity-15 mb-5" />
        <Typography
          variant="subtitle1"
          fontWeight={700}
          color="primary"
          className="!mb-1"
        >
          You have not added any request
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          className="text-center"
        >
          Earn points by engaging with activities, and redeem them for exciting
          rewards. Let's get started today!
        </Typography>
      </Box>
    }

      
    </>
  );
};

export default memo(RequestTab);

const RequestCard = ({ theme, title, Description, Date, Points, DOC }: any) => {
  return (
    <Card
      className="border-b-2 border border-gray-100"
      sx={{
        borderBottomColor: theme.palette.warning.main,
      }}
    >
      <div className="flex items-center ">
        <Typography variant="h6" className="flex flex-1">
          {title}
        </Typography>
        <Chip
          label="Requested"
          sx={{
            backgroundColor: theme.palette.warning.lighter,
            color: theme.palette.warning.main,
          }}
        />
      </div>

      <Typography
        variant="body2"
        color="secondary.main"
        className="text-justify !mt-4"
      >
        {Description}
      </Typography>
      <div className="flex items-center mt-2 justify-between">
        {Points ? (
          <div className="flex items-center gap-2 ">
            <img src={CoinsImg} alt="coins" className="w-[5%]" />
            <Typography variant="subtitle1" className="!font-semibold">
              {Points} <span className="text-[10px] font-light">points</span>
            </Typography>
          </div>
        ) : (
          <div className="flex flex-1" />
        )}

        <Typography variant="body2" color="secondary.light">
          {dayjs(Date).fromNow()}
        </Typography>
      </div>
    </Card>
  );
};
